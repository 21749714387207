<template>
  <section>
    <h3 class="mb-8 text-h5">
      Registro de sanciones a proveedores no registrados
    </h3>

    <v-row>
      <v-col>
        <v-btn
          color="secondary"
          class="mr-4"
          :to="{ name: 'sancion-proveedor-no-registrado' }"
          :disabled="!crearSancion"
          >
          Agregar sanción</v-btn
        >
        <v-btn 
          color="secondary" 
          @click="dialogCsv = true"
          :disabled="!editarSancion"
          >Cargar csv</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="8">
        <v-text-field
          v-model="filtroBusqueda"
          outlined
          label="Buscar por: nombre de proveedor, nombre comercial o documento"
          hide-details
          clearable
        />
      </v-col>
    </v-row>

    <data-table-component
      v-models:select="perPage"
      v-models:pagina="page"
      :headers="headers"
      :items="sanciones"
      @paginar="paginate"
      :total_registros="totalRows"
      class="mb-8"
    >
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>

      <template v-slot:[`item.EstadoSancion.nombre`]="{ item }">
        <v-chip
          label
          :color="
            [1].includes(item.EstadoSancion.id)
              ? 'success'
              : [2].includes(item.EstadoSancion.id)
              ? 'warning'
              : [5].includes(item.EstadoSancion.id)
              ? '#ff735a' //Cuando el estado de la sancion es Inhabilitado
              : 'info'
          "
          text-color="white"
          v-text="item.EstadoSancion.nombre"
        />
      </template>

      <template v-slot:[`item.acciones`]="{ item }">
        <!-- Editar registro de sancion -->
        <v-btn icon @click="getSancioPreRegistro(item.id)">
          <v-icon v-if="!editarSancion" color="secondary">mdi-eye</v-icon>
          <v-icon v-else color="secondary">mdi-pencil</v-icon>
        </v-btn>
        <!-- Eliminar registro de sancion -->
        <v-btn 
          :disabled="!eliminarSancion"
          icon 
          @click="(showDeleteUserDialog = true), (userSelectedToAction = item)"
          >
          <v-icon 
            color="secondary"
            >mdi-delete-alert</v-icon>
        </v-btn>
      </template>
    </data-table-component>

    <v-dialog v-model="dialogCsv" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Cargar csv</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-file-input
                v-model="csv"
                accept=".csv"
                outlined
                label="Archivo csv"
                hide-details
                clearable
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialogCsv = false"
            >Cancelar</v-btn
          >
          <v-btn color="secondary" @click="subirCsvSanciones">Cargar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Modal -->
    <v-dialog v-model="modal_sancion_prov_st" persistent max-width="900px">
      <v-card class="pa-4">
        <v-row>
          <!-- corner button -->
          <v-col cols="1">
            <v-btn
            :class="$vuetify.breakpoint.lgAndUp ? 'closeBtn' : 'ma-0'"
            @click="close"
            icon
            large
            >
            <v-icon v-text="'mdi-close'" color="secondary" />
            </v-btn>
          </v-col>
          <!-- content -->
          <v-col cols="11" class="mt-4">
            <div v-if="sancionSeleccionada">
              <v-row class="d-flex flex-column">
                <div class="d-flex flex-column my-2">
                <span class="text-caption" v-text="'Correlativo'" />
                <span v-text="sancionSeleccionada?.correlativo" />
                </div>

                <div class="d-flex flex-column mb-2">
                  <span class="text-caption" v-text="'Fecha inicio de sanción'" />
                  <span v-text="moment(sancionSeleccionada?.fecha_inicio).format('DD/MM/YYYY')"/>
                </div>

                <div class="d-flex flex-column mb-2">
                  <span class="text-caption" v-text="'Fecha fin de sanción'" />
                  <span v-text="moment(sancionSeleccionada?.fecha_fin).format('DD/MM/YYYY')"/>
                </div>

                <div class="d-flex flex-column my-2">
                  <span class="text-caption" v-text="'Detalle de sanción'" />
                  <span v-text="sancionSeleccionada?.detalle_sancion" />
                </div>

                <div class="d-flex flex-column my-2">
                  <span class="text-caption" v-text="'Descargar documento de sanción'" />
                  <v-btn 
                    v-if="sancionSeleccionada?.doc_sancion"
                    outlined 
                    color="secondary" 
                    class="download-btn"
                    @click="descargarDocumentoSancion"
                    >
                    Descargar documento
                  </v-btn>
                </div>

              </v-row>

              <!-- button to close -->
              <v-row class="mb-3 mt-12">
              <v-btn
              @click="close"
              class="secondary--text text-capitalize"
              text
              v-text="'volver'"
              />
              </v-row>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDeleteUserDialog" max-width="700" persistent>
      <v-card rounded="lg">
        <v-card-title class="d-flex justify-center primary--text">
          <p class="mt-5">{{ `¿Desea eliminar la sanción para el registro ${userSelectedToAction?.correlativo}?` }}</p>
        </v-card-title>
        <v-card-text class="d-flex justify-center secondary--text">
          <p>De confirmarse la siguiente acción, debe cargar una resolución.</p>
        </v-card-text>
        <v-card-text class="d-flex justify-center">
          <v-file-input
            v-model="adjunto"
            label="Adjunto"
            placeholder="Seleccione un PDF"
            outlined
            light
            accept="application/pdf"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-center secondary--text">
          <v-btn
            color="primary"
            class="mb-5"
            outlined
            @click="cerrarModalEliminar()"
          >
            Cancelar
          </v-btn>
          <v-btn
          :disabled="!adjunto"
            color="primary"
            class="mb-5"
            @click="deleteRegistroSancion()"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </section>
</template>

<script>
  import DataTableComponent from "../../components/DataTableComponent.vue";
  import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent.vue";

  export default {
    name: "sancionesPreRegistroView",
    components: {
      DataTableComponent,
      ConfirmationDialogComponent
    },
    data: () => ({
      headers: [
        {
          text: "Codigo",
          value: "correlativo",
        },
        {
          text: "No. Documento",
          value: "PreRegistroProveedor.numero_documento",
        },
        {
          text: "Documento de identidad",
          value: "PreRegistroProveedor.TipoDocumento.nombre",
        },
        {
          text: "Proveedor",
          value: "PreRegistroProveedor.nombre_comercial",
        },
        {
          text: "Fecha de inicio",
          value: "fecha_inicio",
        },
        {
          text: "Fecha de fin",
          value: "fecha_fin",
        },
        {
          align: "center",
          text: "Estado",
          value: "EstadoSancion.nombre",
        },
        {
          align: "center",
          text: "Tipo de sancion",
          value: "TipoSancionProveedor.nombre",
        },
        {
          align: "center",
          text: "Acciones",
          value: "acciones",
        },
      ],
      sanciones: [],
      page: 1,
      perPage: 10,
      totalRows: 0,
      dialogCsv: false,
      csv: null,
      esReadOnly: false,
      crearSancion: false,
      eliminarSancion: false,
      adjunto: null,
      modal_sancion_prov_st: false,
      sancionSeleccionada: null,
      showDeleteUserDialog: false,
      userSelectedToAction: null,
      filtroBusqueda: null,
    }),
    methods: {
      paginate(params) {
        const { cantidad_por_pagina, pagina } = params;
        this.perPage = cantidad_por_pagina;
        this.page = pagina;

        this.getAllSanciones();
      },
      async getAllSanciones() {

        const { status, data, headers } =
          await this.services.Sanciones.getAllSancionesPreRegistro({
            page: this.page,
            per_page: this.perPage,
            filtro_busqueda: this.filtroBusqueda ? this.filtroBusqueda : undefined,
          });

        if (status == 200) {
          this.sanciones = data;
          this.totalRows = Number(headers.total_rows);
        }

      },
      async deleteRegistroSancion() {
          this.id_sancion = this.userSelectedToAction.id;

          const formData = new FormData();    
          formData.append('adjunto', this.adjunto);

          const response = await this.services.Sanciones.deleteRegistroSancion(this.id_sancion, formData)
          
          if (response?.status === 200) {
            this.temporalAlert({
              show: true,
              type: "success",
              message: "Registro eliminado con éxito",
            });
            this.showDeleteUserDialog = false;
            this.userSelectedToAction = null;
            this.getAllSanciones();
          }
      },
      getSancioPreRegistro(id) {
        this.id_sancion = id;
        if (!this.editarSancion) {
          this.modal_sancion_prov_st = true;
          const selectedSancion = this.sanciones.find(sancion => sancion.id === id);
          if (selectedSancion) {
            this.sancionSeleccionada = selectedSancion;
          }
        } else {
          this.$router.push({
            name: "sancion-proveedor-no-registrado",
            params: { id_sancion: id },
          });
        }
      },
      close() {
        this.modal_sancion_prov_st = false
      },
      cerrarModalEliminar() {
        this.showDeleteUserDialog=false;
        this.adjunto= null;
      },
      async subirCsvSanciones() {

        // Evniamos el archivo csv por medio de un form data
        const formData = new FormData();
        formData.append("file", this.csv);

        const { status, data } =
          await this.services.Sanciones.subirCsvSanciones(formData);

        if (status == 200) {
          this.temporalAlert({
            show: true,
            text: "Las sanciones han sido registradas correctamente",
            type: "success",
          });
          this.dialogCsv = false;
          this.getAllSanciones();
        }

      },

      async descargarDocumentoSancion() {
      const response = await this.services.PacProcesos.descargarDocumentoProceso({
          disk: "sanciones",
          ruta: this.sancionSeleccionada.doc_sancion,
        });

      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar el documento",
          type: "error",
        });

        return;
      }

      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const file = new File([blob], "Sancion_" + this.sancionSeleccionada.correlativo + ".pdf", {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "Sancion_" + this.sancionSeleccionada.correlativo + ".pdf";
      link.click();  
    },
      verificarRol() {
        // this.esReadOnly = this.haveRole("ROLE_LISTADO_PROVEEDORES_SANCIONADOS");
        this.editarSancion = this.haveRole("ROLE_RUPES_EDITAR_SANCION_PROVEEDORES_NO_REGISTRADO");
        this.crearSancion = this.haveRole("ROLE_RUPES_CREAR_SANCION_PROVEEDORES_NO_REGISTRADO");
        this.eliminarSancion = this.haveRole("ROLE_DELETE_REGISTRO_SANCION_VIEW");
      },
    },
    watch: {
      filtroBusqueda() {
          this.page = 1;
          this.getAllSanciones();
      },
    },
    async created() {
      this.verificarRol(); // Verificar el rol al crear el componente
      await this.getAllSanciones();
    },
  };
</script>


<style scoped>
  .download-btn {
    width: fit-content; 
    /* max-width: 300px; */
  }
</style>

